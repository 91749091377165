.app {
  width: 100%;
  height: 100%;

  .body {
    padding-top: 50px;

    .page {
      position: absolute;
      width: 100%;

      &:global(.enter-active) {
        animation-name: enter;
        animation-duration: .3s;
      }

      &:global(.exit-active) {
        animation-name: exit;
        animation-duration: .3s;
      }
    }
  }
}

@keyframes enter {
  from {
    opacity: 0;
    transform: translateX(50%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes exit {
  from {
    opacity: 1;
    transform: translateX(0%);
  }

  to {
    opacity: 0;
    transform: translateX(-50%);
  }
}
