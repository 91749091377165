.productList {
  display: flex;
  flex-direction: column;
  padding: 20px;

  .items {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    flex-wrap: wrap;

    & > a:nth-child(odd) {
      margin-right: 10px;
    }
  }
}
