@import "~spoqa-han-sans/css/SpoqaHanSans-kr.css";
@import "~@fontsource/lato";

* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

html, body, #root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2f3640;
  outline: none;
  background: #eeece8;
  font-family: 'Spoqa Han Sans', 'Apple SD Gothic Neo', 'serif';
}

a {
  display: flex;
  text-decoration: none;
  color: inherit;
}

model-viewer {
  width: 100%;
  height: 100%;

  button {
    background: #fff;
    border: none;
    text-align: center;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
    color: #2f3640;
    font-weight: bold;
    padding: 4px;
    font-size: 12px;
    border-radius: 2px;
  }
}
