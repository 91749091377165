.productItem {
  width: calc(50% - 5px);
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .image {
    width: 100%;
    background-size: 80% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    margin-top: 5px;
  }

  .information {
    display: flex;
    flex-direction: column;
    padding: 15px;

    .price {
      font-size: 18px;
      margin-bottom: 6px;
      line-height: 1;
    }

    .name {
      font-size: 14px;
      margin-bottom: 6px;
      font-weight: normal;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .author {
      font-size: 12px;
      color: #9b9a97;
      font-family: 'Lato', 'Spoqa Han Sans', 'Apple SD Gothic Neo', 'serif';
      line-height: 1;
    }

    .footer {
      display: flex;
      justify-content: space-between;
    }

    .additional {
      font-size: 12px;
      color: #9b9a97;
      display: flex;

      span {
        margin-right: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          margin-right: 2px;
        }
      }
    }
  }
}
