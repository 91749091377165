.viewer {
  width: 100%;
  height: 100%;
  display: flex;

  .arButton {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 100px;
    background: url(../../styles/ar.svg) no-repeat center;
  }

  .qrCode {
    width: 200px;
    height: 250px;
    position: absolute;
    bottom: 60px;
    right: 60px;
    margin: auto;
    background-image: url(../../styles/frame.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #ffffff;
    box-shadow: 2px 0px 12px rgba(0, 0, 0, 0.15);
    background-size: 70%;
    border-radius: 16px;
    outline: none;
    transform-origin: bottom right;
  }
}
