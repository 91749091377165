.productDetail {
  width: 100%;
  min-height: calc(100vh - 50px);
  padding: 20px;
  background: #ffffff;
  box-sizing: border-box;

  .header {
    display: flex;
    margin-bottom: 6px;

    .navigator {
      font-size: 12px;
      color: #9b9a97;
    }
  }

  .viewer {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    height: 1000px;
    background: #fafafa;
    margin-bottom: 6px;
    border: solid rgba(0, 0, 0, 0.1) 1px;
  }

  .colorPalette {
    display: flex;
    margin-bottom: 16px;

    .color {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 2.5 + px;
      border: solid rgba(0, 0, 0, 0.1) 1px;

      &.color1 {
        background: #f3a683;
      }

      &.color2 {
        background: #e77f67;
      }

      &.color3 {
        background: #778beb;
      }

      &.color4 {
        background: #786fa6;
      }
    }
  }

  .information {
    display: flex;
    flex-direction: column;

    .author {
      font-size: 12px;
      margin-bottom: 2px;
      color: #9b9a97;
      font-family: "Lato", "Spoqa Han Sans", "Apple SD Gothic Neo", "serif";
      line-height: 1;
    }

    .name {
      font-size: 16px;
      margin-bottom: 2px;
    }

    .price {
      font-size: 32px;
      margin-bottom: 12px;
    }

    .text {
      color: #9b9a97;
      font-size: 14px;
      line-height: 1.8;
      white-space: pre-wrap;
    }
  }
}

.buyButtonWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  padding: 20px;
  box-sizing: border-box;

  button {
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
    color: #fff;
    font-size: 20px;
    background: #e67e22;
    position: relative;
    border-radius: 12px;
    box-shadow: 0 4px 24px 1px rgba(#e67e22, 0.4),
      0 2px 12px 1px rgba(#d35400, 0.2);
  }
}
