.embed {
  background: #ffffff;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
