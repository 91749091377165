.header {
  width: 100%;
  height: 50px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background: #ffffff;

  display: flex;
  justify-content: space-between;

  .logo {
    width: 120px;
    height: 100%;
    background: url(../../images/logo.png) no-repeat left 20px center;
    background-size: 60%;
  }


  .gnb {
    width: 60px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}
